import { j as a } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as i } from "./chunks/clsx.0d472180.js";
import { forwardRef as d } from "react";
import { o as t, t as m } from "./chunks/styles.9b2ce419.js";
import { m as p } from "./chunks/motion.24089995.js";
import { AnchorOrButton as c } from "./anchorOrButton.mjs";
const o = d(({ parentId: r, active: e, children: n, ...s }, l) => /* @__PURE__ */ a.jsxs(
  c,
  {
    ref: l,
    role: "tab",
    className: i(t.root, e && t.active),
    "aria-selected": e,
    tabIndex: e ? 0 : -1,
    ...s,
    children: [
      /* @__PURE__ */ a.jsx("span", { className: t.label, children: n }),
      e && /* @__PURE__ */ a.jsx(
        p.div,
        {
          initial: !1,
          layoutId: `indicator-${r}`,
          className: t.indicator,
          style: { borderRadius: m.borderRadiusL }
        }
      )
    ]
  }
));
try {
  o.displayName = "SegmentedControlOption", o.__docgenInfo = { description: "", displayName: "SegmentedControlOption", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, parentId: { defaultValue: null, description: "", name: "parentId", required: !1, type: { name: "string" } }, active: { defaultValue: null, description: "", name: "active", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  o as SegmentedControlOption
};
